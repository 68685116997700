.job-container {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
  position: relative;
}

.job-main-content {
  margin-right: 2rem;
}

.job-section {
  margin: 2rem 0;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.job-sidebar {
  position: sticky;
  top: 100px;
  height: fit-content;
}

.job-section h3 {
  color: #2C3E50;
  margin-bottom: 1rem;
}

.location {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

h2 {
  color: #FF5F6D;
  margin: 1rem 0;
}

.job-section ul {
  list-style-type: none;
  padding: 0;
}

.job-section li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.job-section li:before {
  content: "•";
  color: #FF5F6D;
  position: absolute;
  left: 0;
}

.contact {
  background: #f8f9fa;
  margin-bottom: 1rem;
}

.contact p {
  margin: 0.5rem 0;
}

.apply-button {
  width: 100%;
  padding: 1rem;
  background-color: #FF5F6D;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.apply-button:hover {
  background-color: #ff4957;
}
