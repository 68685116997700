.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-section {
  border-top: 1px solid #e0e0e0;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.form-section h2 {
  color: #2C3E50;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #666;
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  padding: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-color: #FF5F6D;
  box-shadow: 0 0 0 2px rgba(255, 95, 109, 0.1);
}

.error-message {
  color: #ff4957;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.primary-button,
.secondary-button {
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.primary-button {
  background-color: #FF5F6D;
  color: white;
  border: none;
}

.primary-button:hover {
  background-color: #ff4957;
}

.secondary-button {
  background-color: white;
  color: #666;
  border: 1px solid #e0e0e0;
}

.secondary-button:hover {
  background-color: #f5f5f5;
}
.form-group .quill {
  margin-bottom: 1rem;
  border-radius: 4px;
}

.form-group .ql-container {
  min-height: 200px;
  font-size: 16px;
}

.form-group .ql-editor {
  min-height: 200px;
}

.form-group .ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.form-group .ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
