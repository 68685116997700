.application-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.application-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-section {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1.5rem;
}

.form-section:last-child {
  border-bottom: none;
}

.form-section h2 {
  color: #6F4E37;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #666;
  font-size: 0.9rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  padding: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  font-family: inherit;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-color: #FF5F6D;
  box-shadow: 0 0 0 2px rgba(255, 95, 109, 0.1);
}

.file-input-container {
  position: relative;
}

.file-input-label {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-input-label:hover {
  background-color: #e0e0e0;
}

.file-input {
  position: absolute;
  left: -9999px;
}

.submit-button {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #FF5F6D;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #ff4957;
}

.error-message {
  color: #ff4957;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.selected-file {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}
