.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dashboard-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.section-title {
  color: #6F4E37;
  font-size: 1.5rem;
  margin: 0;
}

.add-button {
  background-color: #FF5F6D;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover {
  background-color: #ff4957;
}

.items-grid {
  display: grid;
  gap: 1rem;
}

.dashboard-card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.2s;
}

.dashboard-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.card-info h3 {
  color: #6F4E37;
  margin: 0 0 0.5rem 0;
}

.card-info p {
  color: #666;
  margin: 0;
}

.card-actions {
  display: flex;
  gap: 0.5rem;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.2s;
}

.action-button:hover {
  color: #FF5F6D;
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-active {
  background-color: #e6f4ea;
  color: #137333;
}

.status-inactive {
  background-color: #fce8e6;
  color: #c5221f;
}
