.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: #2C3E50;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.topbar-left, .topbar-right {
    flex: 1;
    display: flex;
    align-items: center;
}

.topbar-center {
    flex: 2;
    display: flex;
    justify-content: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3498DB;
    text-decoration: none;
}

.search-container {
    position: relative;
    width: 100%;
    max-width: 500px;
}

.search-input {
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    font-size: 0.9rem;
    color: #FF5F6D;
}

.topbar-search-button {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
}

.search-icon {
    width: 16px;
    height: 16px;
    color: #757575;
}

.topbar-right {
    justify-content: flex-end;
    gap: 1.5rem;
}

.nav-link {
    color: #FED8B1;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.2s;
}

.nav-link:hover {
    color: #FF5F6D;
}
