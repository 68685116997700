.search-results {
    display: grid;
    gap: 20px;
    margin-top: 20px;
}

.job-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.job-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.job-title {
    color: #2C3E50;
    font-size: 1.2rem;
    margin-bottom: 8px;
}

.kita-name {
    color: #FF5F6D;
    font-weight: 500;
    margin-bottom: 4px;
}

.location {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 12px;
}

.description {
    color: #333;
    font-size: 0.95rem;
    line-height: 1.5;
}
