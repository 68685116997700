.start-page {
    min-height: 100vh;
    background-color: #f8f9fa;
}

.hero-section {
    background: linear-gradient(135deg, #2c3e50 0%, #3498db 100%);
    min-height: 80vh;
    display: flex;
    align-items: center;
    position: relative;
    padding: 2rem;
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.1;
}

.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    z-index: 1;
    text-align: center;
}

.hero-title {
    font-size: 3.5rem;
    font-weight: 800;
    color: white;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-subtitle {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 3rem;
    font-weight: 400;
}

.search-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 1rem;
}

.search-wrapper {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 12px;
    padding: 0.5rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.location-icon {
    color: #666;
    margin: 0 0.5rem 0 1rem;
}

.search-input {
    flex: 1;
    border: none;
    padding: 1rem;
    font-size: 1.1rem;
    background: transparent;
}

.search-input:focus {
    outline: none;
}

.hero-search-button {
    background: #3498db;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.hero-search-button:hover {
    background: #2980b9;
    transform: translateY(-1px);
}

.search-icon {
    width: 20px;
    height: 20px;
}

.features-section {
    max-width: 1200px;
    margin: -80px auto 0;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    z-index: 2;
    position: relative;
}

.feature-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
}

.feature-card svg {
    color: #3498db;
    margin-bottom: 1rem;
}

.feature-card h3 {
    color: #2c3e50;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.feature-card p {
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.2rem;
    }

    .search-wrapper {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }

    .search-button {
        width: 100%;
        justify-content: center;
    }

    .features-section {
        margin-top: 2rem;
        grid-template-columns: 1fr;
    }
}
